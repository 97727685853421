import React from "react"
import Post from "./Post"

/*
Used to render a the blogpost list on /blog.
@props: {
  posts: [
    ... all the queried markdown files
  ]
}
*/

const PostList = ({ posts }) => {
  const postList = posts.map((post, index) => {
    return <Post data={post} key={index} />
  })
  return (
    <section className="grid md:grid-cols-2 lg:grid-cols-3 gap-lg">
      {postList}
    </section>
  )
}

export default PostList
