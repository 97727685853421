import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

/*
Used to render a blogpost on /blog.
@props: {
  data: {
    frontmatter: {
      data: {
        slug: string;
        featuredImage: gatsby queried image
        title: string;
      }
    }
  }
}
*/

const Post = ({ data: { frontmatter: data } }) => {
  return (
    <article className="shadow-md rounded bg-white">
      <Link to={data.slug}>
        <Image
          fluid={data.featuredImage.childImageSharp.fluid}
          className="rounded-tr rounded-tl"
        />
      </Link>

      <Link
        to={data.slug}
        className="bg-white w-full block text-center py-md rounded-br rounded-bl hover:bg-darkSand duration-300 font-semibold"
      >
        {data.title}
      </Link>
    </article>
  )
}

export default Post
