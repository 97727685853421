import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/global/Layout"
import PostList from "../components/blogComponents/PostList"
import TextContent from "../components/TextContent"

const Index = () => {
  const {
    allMarkdownRemark: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout
      metaTitle="Blog fyldt med spændende artikler"
      metaDescription="Bliv klogere på omverdenen, og alt der har noget med ferier og rejser at gøre!"
      slug="/blog/"
    >
      <div className="container mt-lg">
        <h1 className="mb-lg">Blog</h1>
        <PostList posts={posts} />
      </div>

      <TextContent
        content="<h2>Få gode råd og opdag verden i vores artikler</h2><p>
    Hos Opholdsguide går vi meget op i at finde de nyeste trends
    indenfor ophold og rejse generelt. Derudover er det vigtigt for os
    at vise verden og inspirere. Derfor kan du forvente at finde et hav
    af forskellige artikler, som ikke kun anmelder hoteller og
    restauranter, men også forskellige seværdigheder. Vi håber, at du
    vil læse med!
  </p>"
      />
    </Layout>
  )
}

export default Index
